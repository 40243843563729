<template>
        <div  style="width: 100%;height: 100%">
            <div style="display: flex;background: #FFFFFF">
                <div style="width: 40px;height: 100%">
                    <img style="width: 40px;height: 36px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/img1.png">
                </div>
                 <el-input v-model="info.material_name"  placeholder="请输入素材名称" maxlength="50" show-word-limit style="height: 40px;margin-left: 16px;width: 604px"></el-input>
            </div>
            <div class="file_style" style="display: flex;justify-content: center;margin-top:2px;">
                <div class="dragger-file">
<!--                    <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx"  class="upload-demo"  action="" drag multiple :http-request="upload_file" :show-file-list="false" >-->
<!--                        <i class="el-icon-upload" ></i>-->
<!--                        <div class="el-upload__text">将文件拖到此处，或<em style="color: #F98B15">点击上传</em></div>-->
<!--                        <div class="format">仅限doc、pdf、ppt等文件格式</div>-->
<!--                    </el-upload>-->
                    <div style="width: 100%;height: 60px;text-align: center;">
                        <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx .txt"    action="" drag multiple :http-request="upload_file" :show-file-list="false" >
                            <div>拖拽文件到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                        </el-upload>
                    </div>
                    <div v-show="info.files.length == 0" style="width: 100%;height: 230px;text-align: center;">
                        <div>扫描二维码手机上传附件</div>
                        <div  style="margin: 0 auto;margin-top: 20px;">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadFile?uuid=' + user.uuid + '&type=12' + '&sign=' + sign"
                                :size="200"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </div>
                    </div>

                    <div v-show="info.files.length > 0" style="width: 100%;line-height: 30px;text-align: center;margin-top: 10px;">
                        <div>扫描二维码手机上传附件<em @mouseenter="isCodeFile = true"  style="color: #aa7700;">点此添加</em> </div>
                        <el-dialog :close-on-click-modal="false" :visible.sync="isCodeFile" width="300px">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadFile?uuid=' + user.uuid + '&type=12' + '&sign=' + sign"
                                :size="250"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </el-dialog>
                    </div>

                </div>
            </div>
            <div v-for="(item,index) in info.files" :key="index" style="margin-top: 5px;width: 100%;overflow:hidden;background: #F1F1F1;">
                <div style="width: 22px;height: 28px;padding: 10px 22px;float: left;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon10.png" style="width: 22px;height: 28px;">
                </div>
                <div style="height: 19px;font-size: 16px;float: left;padding: 13px 0;width: 500px;">{{item.name}}</div>
                <div style="float: right;margin-right: 10px;margin-top: 5px" @click="removeFile(index)" ><el-button  icon="el-icon-delete" circle></el-button></div>
            </div>
            <div style="width: 100%;">
                <div style="display: flex">
                    <div style="width: 90px;height: 21px;font-size: 16px;margin-top: 15px;font-weight: bold"><span style="color: red">*</span>素材分类</div>
                    <div style="margin-top: 5px;"><el-cascader v-model="info.types" :options="type_list"></el-cascader></div>
                    <div style="display: flex;margin-top: 5px;margin-left: 15px;">
                        <div style="width: 90px;height: 21px;font-size: 16px;margin-top: 7px;font-weight: bold"><span style="color: red">*</span>可见范围</div>
                        <el-select v-model="info.auth"  placeholder="请选择">
                            <el-option
                                    v-for="item in authList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div  style="display: flex;margin-left: 450px;margin-top: 10px">
                    <div style="margin: 5px 10px;" @click="contentvideo('x')"><el-button type="info">取消</el-button></div>
                    <div v-if="info.id != 0" style="margin: 5px 10px;" @click="add_attachment(1)"><el-button class="el-icon-s-promotion" type="primary" >保存</el-button></div>
                    <div v-else style="margin: 5px 10px;" @click="add_attachment(0)"><el-button class="el-icon-s-promotion" type="primary" >上传素材</el-button></div>
                </div>
            </div>
        </div>
</template>
<script>

export default {
    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data () {
        return {
            type_list:[],
            authList: [{
                value: 1,
                label: '仅自己可见'
            }, {
                value: 2,
                label: '仅好友可见'
            }, {
                value: 3,
                label: '仅粉丝可见'
            }, {
                value: 4,
                label: '所有人可见'
            }],
            //扫码上传文件
            isCodeFile:false,
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }
    },
    props :{
        roomId:{
            type:Number,
            default(){
                return 0;
            }
        },
        menuId:{
            type:Number,
            default(){
                return 0;
            }
        },
        editId:{
            type:Number,
            default(){
                return 0;
            }
        },
	    info:{
		    type:Object,
		    default(){
			    return {
					id:0,
				    auth:1,
				    material_name:"",
				    files:[],
				    types:""
			    };
		    }
	    },
    },
    watch: {
    },
    mounted:function(){
        var that = this;
        this.getTypeList();

        //监听扫码上传
        this.$EventBus.$EventBus.$off("callbackNotice");
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    let obj = JSON.parse(data.data);
                    if(obj.type == 1001){
                        that.getCodeFiles(obj);
                    }else if(obj.type == 1002){
                        that.delCodeFiles(obj);
                    }
                }
            })
        })
    },
    methods:{
        getCodeFiles(obj){
            var that = this;
            var obj = {
                name:obj.name,
                url:obj.url,
                hash:obj.hash
            };
            that.info.files.push(obj);
        },

        delCodeFiles(obj){
            var that = this;
            for (let i = 0; i < that.info.files.length; i++) {
                if(that.info.files[i].hash == obj.hash){
                    that.info.files.splice(i,1);
                    that.utils.sus("删除成功");
                    break;
                }
            }
        },

        //附件上传
        upload_file:function(params){
            var that = this;
            var file = params.file;
            that.openLoading('正在上传请稍后')
            that.utils.upload(file,function(url){
	            if (!url){
		            return false;
	            }
                var obj = {
                    name:url.name,
                    url:url.url,
                    hash:url.hash
                };
                that.info.files.push(obj);
            })
        },
        //移除附件
        removeFile:function(index){
            var that = this;
            that.utils.confirm("移除这个附件？",function(){
                that.info.files.splice(index, 1);
            })
        },
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        add_attachment(){
            var that =this;
            var params = {};
            params.form = 1;

            if (that.info.material_name == ""){
                that.utils.err("请填写标题");
                return false;
            }
            params.title = that.info.material_name;
            params.formType = 2;
            params.content = that.info.material_name;
            params.images = [];
            params.videos = [];
            if (that.info.files == ""){
                that.utils.err("请上传附件");
                return false;
            }
            params.enclosure = JSON.stringify(that.info.files);
            params.cover = "";
            params.isComment = 1;
            params.isReprinted =1;
            params.ReprintedAddress = "";
            params.topicId = 0;
            params.channelId = 0;
            params.auth = this.info.auth;
            params.kwTypeArr = JSON.stringify(that.info.types);
            // 附件类型是8
            params.type = 0;
            params.bgbType = 9;
	        params.id = that.info.id;

	        params.roomId = that.roomId;
	        params.menuId = that.menuId;

            if (params.id == 0){
                that.openLoading('正在发布请稍后')
                that.newApi.addknowledge(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.contentvideo();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }else {
                that.openLoading('正在发布请稍后')
                this.newApi.editKnowledge(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.contentvideo();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }

        },
        contentvideo(val){
            if(val == 'x'){
                this.$emit('custom-event');
            }else {
                this.info.id = 0;
                this.info.auth = 1;
                this.info.files = [];
                this.info.material_name = "";
                this.info.types = "";
                this.$emit('custom-event');
                this.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
            }
        },
    }
}
</script>
<style>
.v-modal{
    z-index: 0 !important;
}
</style>
<style  scoped>
     /deep/  .dragger-file .upload-demo .el-upload-dragger {
         width: 660px !important;
         margin-top: 10px;
     }
     /deep/ .el-checkbox__inner {
         width: 16px;
         height: 17px;
     }
     /deep/ .el-checkbox__inner::after {
         left: 5px;
         top: 1px;
         height: 10px;
     }
     /deep/ .el-checkbox__label {
         font-size: 16px;
     }
     /deep/ .el-upload-dragger {
         border: 0px;
         background: none;
         height: 30px;
         margin-top: 10px;
     }
     /deep/ .el-dialog__header{
         padding: 0px;
     }
</style>
