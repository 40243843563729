<template>
    <div class="input" v-loading="loading">
        <el-input v-model="content" type="textarea" :rows="3" :maxlength="5000" :autosize="{ minRows: 3, maxRows: 6}" :show-word-limit="true" minlength="10" placeholder="请输入内容"></el-input>
        <div  class="bottom">
            <div  class="btns"></div>
            <el-dropdown trigger="click">
                <div class="btn">
                    <img src="../imgs/link.png" style="margin-right:10px;width: 16px;height: 16px;">
                    链接
                </div>
                <el-dropdown-menu slot="dropdown">
                    <div style="overflow: hidden; width: 320px;">
                        <el-input v-model="linkContent" placeholder="请输入链接地址" style="width: 260px; margin: 0 10px"></el-input>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="send hand op"  @click="edit">发布</div>
        </div>
    </div>

</template>

<script>
    export default {
        name:"release",
        components: {
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        data () {
            return {
                loading: false,
                // 文字内容
                content:"",
                // 链接
                linkContent: '',

            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {}
                }
            },

        },
        created:function(){

        },
        mounted: function () {
            this.linkContent = this.data.content.link;
            this.content = this.data.content.content;
        },
        methods:{

            // 发布
            edit() {
                var that = this
                let params = {};
                let content = {};
                content.images = [];
                content.videos = [];
                content.enclosure = [];
                content.link = that.linkContent;
                content.content = that.content;
                if (/^\s*$/.test(that.content)) {
                    that.utils.err("请输入内容");
                    return false;
                }
                params.content = JSON.stringify(content);
                params.id = that.data.id;
                that.newApi.editTemplateContent(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.$emit('custom-event');
                        that.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
                    }
                })

            },
        }
    }

</script>

<style>
    .input{
        padding:10px;
        background-color: #ffffff;
    }

    .input .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
    }
    .input .bottom .btns{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .input .bottom .btns .btn{
        width: 72px;
        display: flex;
        position: relative;
        margin-right: 22px;
        justify-content: center;
        align-items: center;
        height: 18px;
        font-size: 16px;
        color: #6B6B6B;
        cursor: pointer;
    }
    .input .bottom .send{
        width: 88px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        background: #2970FF;
        border-radius: 45px 45px 45px 45px;
    }

    .input textarea {
        background-color: #F0F2F5 !important;
    }

    .input .el-input-group {
        height: 20px;
    }
    /deep/ .input .el-input-group__prepend{
        color: #555555 !important;
        background-color: #FFFFFF;
    }

    .at_user{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;overflow: hidden;padding: 0 5px;
    }
    .at_user.active{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;background: #C7E0F4;overflow: hidden;padding: 0 5px;
    }
</style>
<style scoped>
    /deep/ .el-dialog__header{
        padding: 0;
    }

    /deep/ .el-dialog__body{
        padding: 0;
    }

    /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background-color: #031F88;
    }

    /deep/ .el-tabs__item {
        width: 199px;
        text-align: center;
        border-bottom: #031F88 1px solid !important;
    }
    .file_style {
        display: flex;justify-content: center;margin-top:20px;
    }
    .format {
        height: 16px;color: #999999;font-size: 14px;
        line-height: 20px;
    }

    .folder {
        margin-left: 47px;
    }
    .content_type_folder {
        display: flex;
    }
    /deep/ .el-popper {
        z-index: 9 !important;
    }

    /deep/ .dragger-file {
        width: 600px !important;
    }
    /deep/  .dragger-file .upload-demo .el-upload-dragger {
        width: 600px !important;
    }
</style>
