<template>
    <div style="width: 100%;height: 100%;padding: 0px">
        <div style="height: 24px;font-size: 18px;color: #444444;font-weight:bold;display: flex;justify-content: center">上传视频</div>
        <div @click="contentvideo" class="close po sc hand">X</div>
        <div style="border: 1px solid #CECFD0;width: 100%;margin-top: 5px;"></div>
        <template>
            <div :style="videos == ''?'height: 330px':'height: 400px'">
                <div v-if="videos == '' "  style="justify-content: center;">
                    <!--                   <el-upload accept="video/*" ref="clearawayvideo" :on-change="onchange_videos"  style="margin-top: 150px;"  class="upload-demo" action="" drag  :http-request="upload_video" :show-file-list="false">-->
                    <!--                       <i class="el-icon-upload" style="font-size: 96px;"></i>-->
                    <!--                       <div class="el-upload__text">拖拽视频到这里，或<em style="color:#F98B15">点此添加</em></div>-->
                    <!--                       <div style="font-size: 14px;color:#999999">请您确保上传的视频内容符合法律法规，符合公共道德</div>-->
                    <!--                   </el-upload>-->

                    <div style="width: 100%;height: 50px;text-align: center;">
                        <el-upload accept="video/*" ref="clearawayvideo"   style="margin-top: 20px;"  class="upload-demo" action="" drag  :http-request="upload_video" :show-file-list="false">
                            <div>拖拽视频到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                        </el-upload>
                    </div>

                    <div v-show="videos.length == 0" style="width: 100%;height: 2px;text-align: center;">
                        <div>扫描二维码手机上传视频</div>
                        <div  style="margin: 0 auto;margin-top: 20px;">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadVideo?uuid=' + user.uuid + '&type=11' + '&sign=' + sign"
                                :size="200"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </div>
                    </div>

                </div>

                <div style="overflow:hidden;" v-if="videos != ''">
                    <div v-for="(item,index) in videos" :key="index" :style="{backgroundImage:'url('+item.img_url}" style="width:424px;height:200px;float:left;background-size:100% 100%;position:relative;margin:15px 30px;border-radius:5px;">
                        <div style="width:100%;height:100%;background-color: rgba(0,0,0,.3);">
                            <img :src="ossUrl + 'img/video_play.png'" style="width:60px;height:60px;position: absolute;top: 45%;left: 45%;border-radius:5px;" />
                        </div>
                        <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeVideo(index)">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>
                    <div class="spanp" ><em>*</em> 视频描述</div>
                    <div style="width: 424px;margin-left: 30px;margin-top: 10px">
                        <el-input type="textarea" :rows="5" placeholder="请输入该视频描述" v-model="inputvideo"  show-word-limit />
                    </div>
                </div>
            </div>
        </template>

        <div style="border: 1px solid #CECFD0;width: 100%;margin-top: 5px;"></div>
        <div style="display: flex;justify-content: center;margin-top: 15px">
            <div @click="contentvideo"><el-button plain>取消</el-button></div>
            <div v-if=" editId != 0 " style="margin-left: 10px;"><el-button @click="addvideo(1)" type="primary" class="el-icon-s-promotion" >保存</el-button></div>
            <div v-else style="margin-left: 10px;"><el-button @click="addvideo(0)" type="primary" class="el-icon-s-promotion">上传视频</el-button></div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data () {
        return {
            videos:[],
            inputvideo:'',
            removeVideos:[],
            //扫码上传文件
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    props :{
        roomId:{
            type:Number,
            default(){
                return 0;
            }
        },
        menuId:{
            type:Number,
            default(){
                return 0;
            }
        },
        editId:{
            type:Number,
            default(){
                return 0;
            }
        },
    },

    created:function(){

    },
    mounted:function(){
        var that = this;
        if(this.editId > 0){
            this.getRoomContentInfo();
        }
        //监听扫码上传
        this.$EventBus.$EventBus.$off("callbackNotice");
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    let obj = JSON.parse(data.data);
                    if(obj.type == 1001){
                        that.getCodeFiles(obj);
                    }else if(obj.type == 1002){
                        that.delCodeFiles(obj);
                    }
                }
            })
        })
    },
    methods:{
        getRoomContentInfo: function () {
            var that = this;
            this.newApi.getRoomContentInfo({
                id: that.editId
            }).then((res) => {
                that.videos = res.data.content.videos;
                that.inputvideo = res.data.content.content;
            }).catch((err) => {
                console.log(err)
            })
        },

        getCodeFiles(obj){
            var that = this;
            let pa = {
                name:obj.name,
                img_url:obj.url + "?x-oss-process=video/snapshot,t_0,f_jpg",
                url:obj.url,
                hash:obj.hash,
            };
            that.videos.push(pa);
        },

        delCodeFiles(obj){
            var that = this;
            for (let i = 0; i < that.videos.length; i++) {
                if(that.videos[i].hash == obj.hash){
                    that.videos.splice(i,1);
                    that.utils.sus("删除成功");
                    break;
                }
            }
        },

        // 上传视频
        upload_video(params) {
            var file = params.file;
            var that = this;

            if (file.type.indexOf('video') > -1) {
                that.utils.upload(file,function(url) {
                    if (!url){
                        return false;
                    }
                    let pa = {
                       name:url.name,
                       img_url:url.url + "?x-oss-process=video/snapshot,t_0,f_jpg",
                       url:url.url,
                       hash:url.hash,
                    };

                    that.videos.push(pa);
                })
            }else{
                this.$message.error('只能上传视频！')
                return false
            }
        },
        removeVideo:function(index){
            var that = this;
            that.utils.confirm("移除这个视频？",function(){
                that.removeVideos.push(that.videos[index]);
                that.videos.splice(index,1);
                that.$refs['clearawayvideo'].clearFiles();
                that.spaceSize = that.spaceSize - that.videos[index].size;
            })
        },
        addvideo (status){
            var that =this;
            var params = {};
            let content = {};
            if (that.inputvideo == ""){
                that.utils.err("请填写视频描述");
                return false;
            }

            content.images = [];
            content.enclosure = [] ;
            if (that.videos.length == 0){
                that.utils.err("请上传视频");
                return false;
            }
            content.videos = that.videos;
            content.bgbType = 8;
            content.content = that.inputvideo;

            params.roomId = that.roomId;
            params.menuId = that.menuId;
            params.data = "{}";
            params.content = JSON.stringify(content);
            console.log(params);

            if (this.editId == 0){
                that.openLoading('正在发布请稍后');
                that.newApi.addRoomTemplateContent(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.videos = [];
                        that.inputvideo = "";
                        that.contentvideo();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }else {
                params.id = this.editId;
                that.openLoading('正在发布请稍后');
                this.newApi.editTemplateContent(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.contentvideo();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }

        },
        contentvideo(val){
            if(val == 'x'){
                this.$emit('custom-event');
            }else{
                this.videos = [];
                this.inputvideo = "";
                this.$emit('custom-event');
                this.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
            }
        },

    }
}
</script>
<style scoped>
.close {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #E4E6EB;
    position: absolute;
    right: 15px;
    top: 18px;
    line-height: 34px;
    text-align: center;
    color: #707070;
}
/deep/ .el-upload-dragger {
    border: 0px;
    background: none;
    height: 30px;
}
.label {
    width: 100px;
    height: 40px;
    font-size: 18px;
    margin-top: 15px;
    margin-left: 30px;
}
em{
    color:#FF0000;
}
.formItem {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 20px;
}
.spanp {
    margin-left: 30px;
    font-size: 16px;
    color: #444444;
    font-weight: bold;
}


</style>
