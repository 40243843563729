<template>
        <div  style="width: 100%;height: 100%">
            <el-tabs v-model="file_tag_name" type="border-card">
                <div @click="contentEnclosure('x')" class="close po sc hand" style="float: right;">X</div>
                <div class="content_type_folder">
                    <div lcass="folder" style="float: left;"><svg t="1666784999025" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2617" width="45" height="30"><path d="M855.04 385.024q19.456 2.048 38.912 10.24t33.792 23.04 21.504 37.376 2.048 54.272q-2.048 8.192-8.192 40.448t-14.336 74.24-18.432 86.528-19.456 76.288q-5.12 18.432-14.848 37.888t-25.088 35.328-36.864 26.112-51.2 10.24l-567.296 0q-21.504 0-44.544-9.216t-42.496-26.112-31.744-40.96-12.288-53.76l0-439.296q0-62.464 33.792-97.792t95.232-35.328l503.808 0q22.528 0 46.592 8.704t43.52 24.064 31.744 35.84 12.288 44.032l0 11.264-53.248 0q-40.96 0-95.744-0.512t-116.736-0.512-115.712-0.512-92.672-0.512l-47.104 0q-26.624 0-41.472 16.896t-23.04 44.544q-8.192 29.696-18.432 62.976t-18.432 61.952q-10.24 33.792-20.48 65.536-2.048 8.192-2.048 13.312 0 17.408 11.776 29.184t29.184 11.776q31.744 0 43.008-39.936l54.272-198.656q133.12 1.024 243.712 1.024l286.72 0z" p-id="2618" fill="#f4ea2a"></path></svg></div>
                    <el-input type="textarea" :rows="3" :maxlength="1000" :autosize="{ minRows: 3, maxRows: 6}" :show-word-limit="true" v-model="enclosureInfo.content.content" style="width: 600px;margin-left:5px;"></el-input>
                </div>
                <div class="file_style" style="display: flex;justify-content: center;margin-top:2px;">
                    <div class="dragger-file">
<!--                        <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx"  class="upload-demo"  action="" drag multiple :http-request="upload_file" :show-file-list="false" >-->
<!--                            <i class="el-icon-upload" ></i>-->
<!--                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
<!--                            <div class="format">仅限doc、pdf、ppt等文件格式</div>-->
<!--                        </el-upload>-->
                        <div style="width: 100%;height: 60px;text-align: center;">
                            <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"    action="" drag multiple :http-request="upload_file" :show-file-list="false" >
                                <div>拖拽文件到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                            </el-upload>
                        </div>
                        <div  style="width: 100%;height: 230px;text-align: center;">
                            <div>扫描二维码手机上传附件</div>
                            <div  style="margin: 0 auto;margin-top: 20px;">
                                <vue-qr
                                    :text="qr_imgUrl + 'uploadFile?uuid=' + user.uuid + '&type=12' + '&sign=' + sign"
                                    :size="200"
                                    :logoSrc="qr_logo"
                                    :logoScale="0.2">
                                </vue-qr>
                            </div>
                        </div>



                    </div>
                </div>
            </el-tabs>
            <div style="overflow: hidden; height:20px;line-height:20px; padding: 0 16px; margin-bottom: 10px">
                <div style="float:left;color:#acacac;">
                    附件:（{{enclosureInfo.content.enclosure.length}}）<!-- /9 -->
                </div>
            </div>
            <div style="overflow:hidden;">
                <div class="video" v-for="(item,index) in enclosureInfo.content.enclosure" :key="index" style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;" :title="item.name">
                    <div class="background" style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                        <!-- <div style="width:100%">{{item.file_name}}</div> -->
                        <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;" />
                    </div>
                    <div style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{item.name}}</div>
                    <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeFile(index)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: flex-end;">
                <el-button style="width: 80px; height: 34px; border-radius: 5px; margin: 10px 20px" @click="contentEnclosure('x')">取消</el-button>
                <el-button v-if="enclosureInfo.id != 0" @click="add_attachment(1)" style="width: 80px; height: 34px; border-radius: 5px; background-color: #031F88; color: #fff; margin: 10px 16px 10px 0" >保存</el-button>
                <el-button v-else style="width: 80px; height: 34px; border-radius: 5px; background-color: #031F88; color: #fff; margin: 10px 16px 10px 0;" @click="add_attachment(0)">确定</el-button>
            </div>
        </div>
</template>
<script>

export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data () {
        return {
            file_tag_name: 'pc',
            files:[],
            enclosureInfo:{
                id:0,
                content:{
                    content:"",
                    enclosure:[]
                }
            },
            //扫码上传文件
            qr_imgUrl:this.$store.state.mobileUrl,
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            sign:this.utils.random_string(6),
        }
    },
    props :{
        roomId:{
            type:Number,
            default(){
                return 0;
            }
        },
        menuId:{
            type:Number,
            default(){
                return 0;
            }
        },
        editId:{
            type:Number,
            default(){
                return 0;
            }
        },
    },
    watch: {
    },
    mounted:function(){
        var that = this;
        if(this.editId > 0){
            this.getRoomContentInfo();
        }
        //监听扫码上传
        this.$EventBus.$EventBus.$off("callbackNotice");
        this.$EventBus.$on("callbackNotice", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    let obj = JSON.parse(data.data);
                    if(obj.type == 1001){
                        that.getCodeFiles(obj);
                    }else if(obj.type == 1002){
                        that.delCodeFiles(obj);
                    }
                }
            })
        })
    },
    methods:{
        getRoomContentInfo: function () {
            var that = this;
            this.newApi.getRoomContentInfo({
                id: that.editId
            }).then((res) => {
                that.enclosureInfo = res.data;
            }).catch((err) => {
                console.log(err)
            })
        },

        getCodeFiles(obj){
            var that = this;
            var obj = {
                name:obj.name,
                url:obj.url,
                hash:obj.hash
            };
            that.enclosureInfo.content.enclosure.push(obj);
        },

        delCodeFiles(obj){
            var that = this;
            for (let i = 0; i < that.enclosureInfo.content.enclosure.length; i++) {
                if(that.enclosureInfo.content.enclosure[i].hash == obj.hash){
                    that.enclosureInfo.content.enclosure.splice(i,1);
                    that.utils.sus("删除成功");
                    break;
                }
            }
        },

        //附件上传
        upload_file:function(params){
            var that = this;
            var file = params.file;
            that.openLoading('正在上传请稍后')
            that.utils.upload(file,function(url){
	            if (!url){
		            return false;
	            }
                var obj = {
                    name:url.name,
                    url:url.url,
                    hash:url.hash
                };
                that.enclosureInfo.content.enclosure.push(obj);
            })
        },
        //移除附件
        removeFile:function(index){
            var that = this;
            that.utils.confirm("移除这个附件？",function(){
                that.enclosureInfo.content.enclosure.splice(index, 1);
            })
        },
        add_attachment(){
            var that =this;
            if (that.enclosureInfo.content.content == ""){
                that.utils.err("请填写内容");
                return false;
            }

            var params = {};
            let content = {};
            content.content = that.enclosureInfo.content.content;
            content.images = [];
            content.videos = [];
            if (that.enclosureInfo.content.enclosure.length == 0){
                that.utils.err("请上传附件");
                return false;
            }
            content.enclosure = that.enclosureInfo.content.enclosure;
            content.bgbType = 9;

	        params.roomId = that.roomId;
	        params.menuId = that.menuId;
            params.data = "{}";
            params.content = JSON.stringify(content);

            if (this.editId == 0){
                that.openLoading('正在发布请稍后');
                that.newApi.addRoomTemplateContent(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.contentEnclosure();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }else {
                that.openLoading('正在发布请稍后');
                params.id = that.editId;
                this.newApi.editTemplateContent(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.contentEnclosure();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }

        },
        contentEnclosure(val){
            if(val == 'x'){
                this.$emit('custom-event');
            }else {
                this.enclosureInfo =  {
                    id:0,
                    content:{
                    content:"",
                        enclosure:[]
                    }
                };
                this.$emit('custom-event');
                this.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
            }
        },
    }
}
</script>
<style  scoped>
/deep/ .el-upload-dragger {
    border: 0px;
    background: none;
    height: 30px;
    margin-top: 10px;
}
/deep/ .el-dialog__header{
    padding: 0px;
}
</style>
